'use client'; // Error components must be Client Components

import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';
import { PageContainer } from '@/app/components/PageContainer/PageContainer';
import { PageHeading } from './components/PageHeading';
import ErrorPage from '../error';
export default function WrappedErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset?: () => void;
}) {
  const partner = usePartner();
  const { t } = useTranslation();

  return (
    <PageContainer
      pageTitle={<PageHeading text={t('error-boundary-header')} />}
      partner={partner}
      name="Error"
      footerDisclaimer={t('common-footer-disclaimer')}
    >
      <ErrorPage error={error} reset={reset} disableHeader />
    </PageContainer>
  );
}
