import classNames from 'classnames';
import { ReactNode } from 'react';

type PageHeadingProps = {
  text: ReactNode;
};

const PageHeading = ({ text }: PageHeadingProps) => (
  <h1
    className={classNames([
      'text-center',
      'tablet:text-left',
      'tablet:px-k-11',
      'tablet:text-header-5',
      'text-body-4-light',
      'tablet:py-k-6',
      'pt-k-6',
    ])}
  >
    {text}
  </h1>
);

export { PageHeading };
